exports.components = {
  "component---src-pages-footer-comp-index-js": () => import("./../../../src/pages/FooterComp/index.js" /* webpackChunkName: "component---src-pages-footer-comp-index-js" */),
  "component---src-pages-home-accommodations-comp-index-js": () => import("./../../../src/pages/Home/AccommodationsComp/index.js" /* webpackChunkName: "component---src-pages-home-accommodations-comp-index-js" */),
  "component---src-pages-home-disert-comp-index-js": () => import("./../../../src/pages/Home/DisertComp/index.js" /* webpackChunkName: "component---src-pages-home-disert-comp-index-js" */),
  "component---src-pages-home-event-comp-index-js": () => import("./../../../src/pages/Home/EventComp/index.js" /* webpackChunkName: "component---src-pages-home-event-comp-index-js" */),
  "component---src-pages-home-hero-comp-index-js": () => import("./../../../src/pages/Home/HeroComp/index.js" /* webpackChunkName: "component---src-pages-home-hero-comp-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-introduction-comp-index-js": () => import("./../../../src/pages/Home/IntroductionComp/index.js" /* webpackChunkName: "component---src-pages-home-introduction-comp-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

